import { ApolloClient } from 'apollo-client';
import axios from 'axios';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as fb from "@/firebase/index.js";
import {
    floorQuery,
    allFormQuery,
    mapQuery,
    faqQuery,
    faqCategoryQuery,
    formCategoryQuery,
    homepageQuery,
    departmentQuery
} from '@/graphql/query';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

import { setContext } from 'apollo-link-context';



  export async function authenticateStrapiUser( username, password) {
    try {
      const response = await axios.post('https://cms.chester.arsconnect.com/auth/local', {
        identifier: username,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Check the response status
      if (response.status !== 200) {
        throw new Error('Strapi Authentication failed');
      }

      // Get the data directly from the response
      const jwtToken = response.data.jwt;

      // Optionally, you can commit a mutation to store the JWT token
      // commit('setToken', jwtToken);

      return jwtToken; // Return the token directly
    } catch (error) {
        console.error('Strapi Authentication error:', (error.response && error.response.data) || error.message);
      throw error; // Re-throw the error for handling in the component
    }
}


export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
        homepage: [],
        floors: [],
        maps: new Map(),
        forms: [],
        faqs: [],
        faqsWithURL : [],
        faqCategory: [],
        formCategories: [],
        formTypes: [],
        formPackets: [],
        fileCache: null,
        count: 0,
        modules: [],
        languages: [],
        kioskLocation: '',
        courts: [],
        defaultLocation: '',
        defaultFormLocation:'',
        departments: [],
        jwtToken:'',
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getModules: state => state.modules,
        getHomepage: state => state.homepage,
        getFloors: state => state.floors,
        getMaps: state => state.maps,
        getFaqs: state => state.faqs,
        getFaqCategories: state => state.faqCategory,
        getForms: state => state.forms,
        getFormCategories: state => state.formCategories,
        getFormType: state => state.formTypes,
        getFormPackets: state => state.formPackets,
        getCourtType: state => state.courtType,
        getLanguages: state => state.languages,
        getKioskLocation: state => state.kioskLocation,
        getCourts: state => state.courts,
        getDefaultLocation: state => state.defaultLocation,
        getDefaultFormLocation: state => state.defaultFormLocation,
        getDepartments: state => state.departments,
        getFaqsWithUrl : state => state.faqsWithURL
    },
    mutations: {

        setToken(state, jwtToken) {
            state.jwtToken = jwtToken;
        },

        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });

            const authLink = setContext((_, { headers }) => {
                // Access the token from the Vuex store
              
                return {
                  headers: {
                    ...headers,
                    Authorization: state.jwtToken ? `Bearer ${state.jwtToken}` : "",
                  }
                };
              });

              const httpLink = new HttpLink({
                uri: uri,
              });

              const link = authLink.concat(httpLink);

            state.apolloClient = new ApolloClient({
                link,
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
        setModules(state, module) {
            state.modules.push(module)
        },
        setLanguages(state, lang) {
            state.languages.push(lang)
        },
        setkioskLocation(state, location) {
            state.kioskLocation = location
        },
        setHomepage(state, page) {
            state.homepage.push(page)
        },
        setFloors(state, floor) {
            state.floors.push(floor)
        },
        setMaps(state, map) {
            state.maps.set(map.mapName.toLowerCase(), map)
        },
        setForms(state, form) {
            state.forms.push(form)
        },
        setFaqs(state, faq) {
            if (faq.showForm) {
                console.log('Faqs', faq)
            }
            if(faq.showUrl){
                console.log("Setting faq and checking url details",faq);
                console.log("Added this faq to faqwithUrl as well");
                state.faqsWithURL.push(faq)
            }
            state.faqs.push(faq)
        },
        setFaqCategory(state, category) {
            state.faqCategory.push(category)
        },
        setFormCategories(state, category) {
            state.formCategories.push(category)
        },
        setFormTypes(state, types) {
            state.formTypes = types
        },
        setFormPackets(state, packet) {
            state.formPackets.push(packet)
        },
        setCourts(state, court) {
            state.courts.push(court)
        },
        setDefaultLocation(state, location) {
            state.defaultLocation = location
        },
        setDefaultFormLocation(state, location) {
            state.defaultFormLocation = location
        },
        setDepartments(state, depart) {
            state.departments.push(depart)
        }
    },
    actions: {



        async createApolloConnection({ commit }, uri) {
                if (uri === '' || uri === null) throw new Error('Empty or Invalid URI');
                try {

                    // get the token
                    const jwtToken = await  authenticateStrapiUser('adminofchester','1@#Backguard')
                    console.log(jwtToken,"jwtToken i recieved")
                    //set the token 
                    commit('setToken', jwtToken);

                    commit('setApolloClient', uri)

                    
                    //resolve apollo
                    return 'Apollo linked successfully & Valid JWT Token added';
                } catch (err) {
                    throw new Error(err.message);
                }
            
        },


        initiateDataPull({ commit, dispatch }, kioskData) {
            commit('setkioskLocation', kioskData.data().locationCode)
            dispatch('indentifyModuleLang', {
                module: kioskData.data().modules,
                language: kioskData.data().languages
            })
            commit("setDefaultLocation", kioskData.data().defaultLocation)
            commit('setDefaultFormLocation',kioskData.data().defaultFormLocation)
            dispatch('identifyAvatarLangCodes', "en")
            //dispatch('updateAutoReferesh', kioskData)
        },
        indentifyModuleLang({ commit, dispatch }, data) {
            data.module.forEach(mod => {
                commit('setModules', mod)
            })
            data.language.forEach(lang => {
                commit('setLanguages', lang)
            })
            dispatch('loadAvatar')
        },
        fetchHomePage({ commit, dispatch, state, getters }) {
            console.log("fetching Home Page : ",getters.getModules)
            return new Promise((resolve, reject) => {
                console.log(getters.getLanguages,"This are the languages")
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: homepageQuery,
                        variables: {
                            "alias": getters.getModules,
                            "lang": lang
                        }
                    }).then(items => {
                        console.log("Items and data",items)
                        items.data.homepages.forEach(page => {
                            console.log("Page inside HomePages : " ,page)
                            dispatch('saveInCache', page.displayIcon.url)
                                .then(response => {
                                    page.displayIcon.url = response
                                    commit('setHomepage', page);
                                })
                        })
                        resolve('Homepage fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },
        // Fetch Floor from strapi
        fetchFloors({ commit, dispatch, state, getters }) {
            // console.log("Kiosk Location",getters.getKioskLocation,getters.getLanguages)
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: floorQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang
                        }
                    }).then(floors => {
                        floors.data.floors.forEach(floor => {
                            // console.log("Floors :", floor)
                            dispatch('saveInCache', floor.floorImage.url)
                                .then(response => {
                                    floor.floorImage.url = response;
                                    commit('setFloors', floor)
                                })
                        })
                        console.log("Before resolving fetchFloor")
                        resolve('Floor data fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        // Fetching Maps from Strapi
        fetchMaps({ commit, state, dispatch, getters }) {
            // console.log("Fetching Maps")
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: mapQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang
                        }
                    }).then(maps => {
                        maps.data.maps.forEach(map => {
                            // console.log("Maps : ",map)
                            dispatch('saveInCache', map.mapImage.url)
                                .then(response => {
                                    commit('setMaps', {
                                        mapName: map.mapName,
                                        mapFloor: map.mapFloor,
                                        mapImage: response,
                                        speech: map.speech,
                                        locale: map.locale,
                                        displayPosition: map.displayPosition
                                    })
                                })
                        })
                        resolve('Map data fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },


        // --------------------------------------------------------- Fetch Data related to Faqs Page -----------------------------------------

        fetchFaqs({ commit, state, getters, dispatch }) {
            console.log("Fetching Faqs:",faqQuery)
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: faqQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(faqs => {
                        // console.log("FAQS are here ??",faqs)
                        //commit('setFaqs', faqs.data.faqs);
                        faqs.data.faqs.forEach(faq => {
                            // console.log(faq,"this is faq");
                            dispatch('fetchFaqCategory', faq.category)
                            let data = {
                                question: faq.question,
                                answer: faq.answer.replace(/\n/g, '<br />'),
                                category: faq.category,
                                linkId: faq.linkId,
                                showMap: faq.showMap,
                                mapName: faq.mapName,
                                locale: faq.locale,
                                showUrl: faq.showUrl,
                                urlDetails:faq.urlDetails,
                                showForm : faq.showform,
                                formName:faq.formName

                            }
                            commit('setFaqs', data)
                        })
                        resolve('FAQs fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        fetchFaqCategory({ commit, state, getters }, faqCategory1) {
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: faqCategoryQuery,
                        variables: {
                            "lang": lang,
                            "category": faqCategory1
                        }
                    }).then(faqCategory => {
                        // console.log("These are all categories",faqCategory);
                        faqCategory.data.faqCategories.forEach(category => {
                            if (getters.getFaqCategories.length === 0) {
                                commit('setFaqCategory', category)
                            } else {
                                let temp = getters.getFaqCategories.filter(faqCat => faqCat.categoryName === faqCategory1)
                                if (temp.length === 0) {
                                    commit('setFaqCategory', category)
                                }
                            }
                        })

                        response('FAQ categories fetched successfully')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        // --------------------------------------------------------- Fetch Data related to Forms Page -----------------------------------------

        fetchFormCategories({ commit, state, getters }) {
            console.log("starting with fetching form categories");
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: formCategoryQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(categories => {
                        categories.data.formCategories.forEach(category => {
                            // if(category.categoryId !== -1){
                                commit('setFormCategories', category)
                            // }
                        })
                        response('Form categories fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },


        fetchForms({ commit, dispatch, state, getters }) {
            console.log("starting with fetching forms");
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: allFormQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(forms => {
                        console.log(forms,"are the any in this forms");
                        forms.data.forms.forEach(form => {
                            // console.log("this is form",form);
                            if (form.documentType !== 'eforms') {
                                dispatch('saveInCache', form.document.url)
                                    .then(response => {
                                        commit('setForms', {
                                            formName: form.formName,
                                            formCategory: form.formCategory,
                                            documentType: form.documentType,
                                            document: response,
                                            documentUrl: form.documentUrl,
                                            locale: form.locale
                                        })
                                    })
                            } else {
                                commit('setForms', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    documentType: form.documentType,
                                    document: null,
                                    documentUrl: form.documentUrl,
                                    locale: form.locale
                                })
                            }
                        })
                        resolve('Form data fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        // ---------------------------------- Departments -----------------------------------------------------------------------

        fetchDepartments({ getters, state, commit }) {
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: departmentQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(departments => {
                        departments.data.departments.forEach(depart => {
                            console.log('Departments fetched: ', depart)
                            commit('setDepartments', depart)
                        })
                        response('Departments fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        // --------------------------------- Data Caching (Image, PDF) ------------------------------------------------------------
        saveInCache({ state, getters }, path) {
            return new Promise((response, reject) => {
                state.fileCache = caches.open('fileCache')
                    .then(cache => {
                        cache.match(getters.getCMSlink + path)
                            .then(cacheResponse => {
                                if (cacheResponse) {
                                    return (cacheResponse.blob())
                                        .then(blob => {
                                            response(URL.createObjectURL(blob))
                                        })
                                } else {
                                    cache.add(getters.getCMSlink + path)
                                    cache.match(getters.getCMSlink + path)
                                        .then(cacheResponse => {
                                            return (cacheResponse.blob())
                                                .then(blob => {
                                                    response(URL.createObjectURL(blob))
                                                })
                                        })
                                }
                            })
                    })
            })
        },

        // ---------------------------------------------- Odessey --------------------------------------------------------------

        searchOdysseybyName({ state }, keyword) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("partyOneName", "==", keyword.toLowerCase())
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this user name.')
                        response(querySnapshot);
                    })
            })
        },
        searchOdysseybyCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("caseNo", "==", caseNumber)
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this case number.')
                        response(querySnapshot);
                    })

            })
        },
        // searchByCaseNo({ state }, caseNumber) {
        //     return new Promise((response, reject) => {
        //         const xmlRequest = document.implementation.createDocument(null, 'Message')
        //         const messageNode = xmlRequest.documentElement
        //         messageNode.setAttribute('MessageType', 'FindCaseByCaseNumber');
        //         messageNode.setAttribute('NodeID', '1');
        //         messageNode.setAttribute('ReferenceNumber', 'ARS-1');
        //         messageNode.setAttribute('UserID', '1');
        //         messageNode.setAttribute('Source', 'Tyler');
        //         const caseNumberNode = xmlDocument.createElement('CaseNumber');
        //         caseNumberNode.textContent = caseNumber;
        //         messageNode.appendChild(caseNumberNode);
        //         const serializer = new XMLSerializer();
        //         const xmlString = serializer.serializeToString(xmlDocument);

        //         let data = qs.stringify({
        //             'siteKey': 'TXELPASOPROD2017',
        //             'odysseyMessageXML': xmlString
        //           });
        //         let config = {
        //             method: 'post',
        //             url: 'http://odyintegration/webservices/apiwebservice.asmx/OdysseyMsgExecution',
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //             },
        //             data: data
        //         };

        //         axios(config)
        //             .then(response => {
        //                 console.log(JSON.stringify(response.data));
        //             })
        //             .catch(function (error) {
        //                 // console.log(error);
        //                 reject(error)
        //             });

        //     })
        // },

        addToLocalStorage({ getters }) {
            localStorage.setItem('floors', getters.getFloors)
            localStorage.setItem('maps', getters.getMaps)
            localStorage.setItem('faqs', getters.getFaqs)
            localStorage.setItem('formcategory', getters.getFormCategories)
            localStorage.setItem('forms', getters.getForms)
            localStorage.setItem('homepages', getters.getHomepage)
            localStorage.setItem('faqswithurl', getters.getfaqsWithURL)
        }
    }
}
