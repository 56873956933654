import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
export default {
  state: {
    qnaStatus: null,
    qnaKeyword: "",
    wrongAnswerCount: 0,
    court: "",
    faqCategory: "",
    isThinking: false,
    requestQna: true,
  },
  getters: {
    getQnaStatus: (state) => state.qnaStatus,
    getQnaKeyword: (state) => state.qnaKeyword,
    getWrongAnsCount: (state) => state.wrongAnswerCount,
    getCourt: (state) => state.court,
    getFaqCategory: (state) => state.faqCategory,
    getIsThinking: (state) => state.isThinking,
    getRequestQna: (state) => state.requestQna,
  },
  mutations: {
    setQnaStatus(state, status) {
      state.qnaStatus = status;
    },
    setQnaKeyword(state, keyword) {
      state.qnaKeyword = keyword;
    },
    setWrongAnswerCount(state, count) {
      state.wrongAnswerCount = count;
    },
    setCourt(state, court) {
      state.court = court;
    },
    setFaqCategory(state, category) {
      state.faqCategory = category;
    },
    setIsThinking(state, status) {
      state.isThinking = status;
    },
    setRequestQna(state, status) {
      state.requestQna = status;
    },
  },
  actions: {
    avatarTalk({ getters, commit }, speechText) {
      commit("setTextBubbleText", speechText);
      window.sayText(
        speechText,
        getters.getAvatarLangCodes.voiceId,
        getters.getAvatarLangCodes.langId,
        getters.getAvatarLangCodes.engineId
      );
    },

    requestQnA({ getters, dispatch, commit }, transcript) {
      // Request to QNA
      if (getters.isThinking) {
        commit("setSpeechTranscript", transcript);
        commit("setTextBubbleText", transcript);
      }
      let data = JSON.stringify({
        top: 3,
        question: transcript,
        includeUnstructuredSources: true,
        confidenceScoreThreshold: "0.2",
        filters: {
          metadataFilter: {
            metadata: [
              {
                key: "scope",
                value: "common",
              },
              {
                key: "scope",
                value: getters.getKioskProfile
                  .data()
                  .locationCode.toLowerCase(),
              },
            ],
            logicalOperation: "OR",
          },
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: i18n.t("qnaEndpoint"),
        headers: {
          "Ocp-Apim-Subscription-Key": "da55d74bdec54b7b856aef2a8e8bbded",
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log(config, "Configguiration");
      commit("setIsThinking", true);
      commit("setTextBubbleText", "Thinking....");
      axios
        .request(config)
        .then((response) => {
          console.log(response.data, "response recived from qna");
          const date = new Date();
            dispatch("addSessionDataToStrapi", {
              applicationId: getters.getKioskProfile.data().applicationId,
              actionType: "QnA",
              applicationSessionId: getters.getSessionId,
              timeStamp: date.toISOString(),
              sessionPayload: {
                module: "QnA",
                action: transcript,
                response: response.data,
                timeStamp: date.toISOString(),
              },
            });


          if (response.data.answers[0].confidenceScore > 0.6) {
            dispatch("generateAnswer", response.data.answers[0]);
          } else if (response.data.answers[0].confidenceScore > 0.45) {
            commit("setIsThinking", false);
            if (getters.getCurrentLanguage === "en") {
              dispatch(
                "avatarTalk",
                "I didn't quite get that, did you mean to ask following questions. Please tap your preference."
              );
            } else {
              dispatch(
                "avatarTalk",
                "No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia."
              );
            }
            let followUp = [];
            response.data.answers.forEach((output) => {
              if (output.confidenceScore > 0.45)
                console.log(output.questions[0], "this is inside followup");
              followUp.push(output.questions[0]);
            });
            var headerVal;
            if (getters.getCurrentLanguage === "en") {
              headerVal = "Please Tap the option you prefer";
            } else {
              headerVal = "Por favor, toque la opción que prefiera";
            }
            console.log(headerVal, followUp, "this are getting dispatched");
            dispatch("openOptionsViewer", {
              dialogHeader: headerVal,
              dialogData: followUp,
            });
          } else {
            commit("setIsThinking", false);
            
            if (getters.getKioskProfile.data().kioskPremise === "Inside") {
              console.log("still learning message premise is Inside");

              if(getters.getKioskProfile.data().locationCode === "PDCAK"){
                dispatch(
                  "avatarTalk",
                  "Sorry I am still learning. Please visit someone at the window for assistance."
                );
              }
              else{
                dispatch(
                  "avatarTalk",
                  "Sorry I am still learning. Please visit the Court Administration Office for more Information."
                );
                //   commit("setTelepresenceViewer", true);
                const mapPath = getters.getMaps.get(
                  getters.getDefaultLocation.toLowerCase()
                );
                console.log(mapPath, " rajat");
                dispatch("openImageViewer", {
                  name: mapPath.mapName,
                  url: mapPath.mapImage,
                });
              }
              
            } else {

              console.log("still learning message premise is Outside");
              dispatch(
                "avatarTalk",
                "Sorry I am still learning and I dont have the answer to this question right now."
              );
              //   commit("setTelepresenceViewer", true);
              // const mapPath = getters.getMaps.get(
              //   getters.getDefaultLocation.toLowerCase()
              // );
              // console.log(mapPath, " rajat");
              // dispatch("openImageViewer", {
              //   name: mapPath.mapName,
              //   url: mapPath.mapImage,
              // });

              // commit("setHelpViewer", true);
            }
          }
        })
        .catch((error) => {
          commit("setIsThinking", false);
          console.log(error, "error is raised here");
        });
    },
    generateAnswer({ dispatch, commit, getters }, result) {
      if (getters.getIsThinking) {
        commit("setQnaStatus", false);
        const metadata = result.metadata.intent;
        const ansObj = result;
        console.log(metadata, ansObj, "inside generate Answer");
        commit("setDefaultBubbleText", false);
        commit("closeOptionsViewer");
        switch (metadata) {
          case "navigate":
            var menuName = ansObj.metadata.navigateto;
            router.addRoute({
              path: menuName.toLowerCase(),
              component: () => import("@/views/" + menuName),
            });
            if (router.currentRoute.path !== menuName) {
              router.push(menuName).then(() => {
                commit("setQnaKeyword", "");
                dispatch("avatarTalk", ansObj.answer);
                let page = i18n
                  .t("homepages")
                  .filter((page) => page.pageType[0].pathName === menuName);
                commit("setPageHeader", page[0].pageType[0].pageHeader);
              });
            } else {
              commit("setQnaKeyword", "");
              dispatch("avatarTalk", ansObj.answer);
            }
            break;

          case "showmap":
            try {
              console.log(
                "At showmap checking premise: ",
                getters.getKioskProfile.data().kioskPremise
              );
              // var mapPath = i18n.t('maps').filter(map => console.log(map,ansObj.metadata.mapposition,"this is what i need"))
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) =>
                    map.displayPosition.toString() ===
                    ansObj.metadata.mapposition
                );
              console.log(mapPath, "this is mapPath");
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
              });
              if (ansObj.answer.trim() !== "default") {
                dispatch("avatarTalk", ansObj.answer + " " + mapPath[0].speech);
              } else {
                dispatch("avatarTalk", mapPath[0].speech);
              }
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarTalk", i18n.t("mapNotFound"));
            }

            break;

          case "speak":
            dispatch("avatarTalk", ansObj.answer);
            break;

          case "showform":
            try {
              console.log("Comming inside showform", ansObj);
              var formName = ansObj.metadata.formname;
              console.log(formName, "this is formName");
              i18n.t("forms").forEach((form) => {
                // console.log(form,formName,"this are formnames");
                if (form.formName.toLowerCase() === formName) {
                  console.log(form, "this are details");
                  dispatch("openPdfViewer", {
                    url: form.document,
                    name: form.formName,
                    desc: "Hello There",
                    emailLink: form.documentUrl,
                    type: form.documentType,
                  });
                  dispatch("avatarTalk", ansObj.answer);
                }
              });
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarTalk", i18n.t("formNotFound"));
            }

            break;
          case "searchbykeyword":
            var redirectedFlag = false
            i18n.t("faqsWithUrl").forEach((faq) => {
              if (faq.linkId.toString() === ansObj.metadata.linkid) {
                var date = new Date()
                if (faq.urlDetails && faq.urlDetails.urllist[0].redirect) {
                  redirectedFlag = true
                  dispatch("addSessionDataToStrapi", {
                    applicationId: getters.getKioskProfile.data().applicationId,
                    actionType: "Session Ended",
                    applicationSessionId: getters.getSessionId,
                    timeStamp: date.toISOString(),
                    sessionPayload: {},
                  });
                  // dispatch('avatarSpeak', 'Redirecting to Website')
                  // this.$store.commit("setIsThinking", false)
                  // this.$store.commit("setSpeechRecognitionHandler", false);
                  
                  window.location.href = faq.urlDetails.urllist[0].link;
                  dispatch("kioskRedirected")
                }
              }
              
            });

            if(!redirectedFlag){
              commit("setQnaKeyword", "");
                  router.addRoute({
                    path: "/faqs",
                    component: () => import("@/views/faqs"),
                  });
                  if (router.currentRoute.path !== "/faqs") {
                    router.push("/faqs");
                  }
                  var page = i18n
                    .t("homepages")
                    .filter((page) => page.pageType[0].pathName === "faqs");
                  commit("setPageHeader", page[0].pageType[0].pageHeader);
                  var linkId = ansObj.metadata.linkid;
                  commit("setQnaKeyword", linkId);
                  dispatch("avatarTalk", ansObj.answer);
            }

            break;

          case "showfaqcategory":
            // commit("setQnaKeyword", "")
            // var faqKeyword = ansObj.metadata.keyword

            // router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
            // if (router.currentRoute.path !== '/faqs') {
            //     router.push('/faqs')
            // }
            // var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
            // commit('setPageHeader', page[0].pageType[0].pageHeader)
            // var count = i18n.t('faqCategory').filter(cat => cat.categoryName.toLowerCase() === faqKeyword)
            // if (count.length === 0) {
            //     var englishFaqList = getters.getFaqs.filter(faq => faq.locale === 'en')
            //     var linkId = englishFaqList.filter(faq => faq.question.toLowerCase().includes(faqKeyword))
            //     if (linkId.length > 0) {
            //         commit("setQnaKeyword", linkId[0].linkId)
            //     } else {
            //         mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
            //         dispatch('openImageViewer', {
            //             name: mapPath.mapName,
            //             url: mapPath.mapImage
            //         })
            //         dispatch('avatarSpeak', i18n.t('kbError'))
            //     }

            // } else {

            //     commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
            // }
            // dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showformcategory":
            var formKeyword = ansObj.metadata.keyword;
            router.addRoute({
              path: "/forms",
              component: () => import("@/views/forms"),
            });
            if (router.currentRoute.path !== "/forms") {
              router.push("/forms");
            }
            commit("setPageHeader", "Forms");
            commit("setQnaKeyword", formKeyword);
            dispatch("avatarTalk", ansObj.answer);
            break;

          case "showformpackets":
            var packetInfo = getters.getFormPacketsEnglish.get(
              ansObj.metadata.find(({ name }) => name === "keyword").value
            );
            dispatch("openPdfViewer", {
              url: packetInfo.packetFiles,
              name: packetInfo.packetName,
              desc: packetInfo.packetDesc,
              emailLink: packetInfo.packetLink,
              type: "Packet",
            });
            //dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showhours":
            dispatch("openCourtTimingViewer", {});
            dispatch("avatarTalk", ansObj.answer);

            break;

          case "session":
            var action = ansObj.metadata.find(
              ({ name }) => name === "action"
            ).value;
            if (action === "still there") {
              dispatch("kioskStillThere");
            } else {
              dispatch("resetKiosk");
            }
            break;

          case "followup":
            console.log(prompts, "this is prompt");
            var prompts = ansObj.dialog.prompts;
            var followUp = [];
            dispatch("avatarTalk", ansObj.answer);
            prompts.forEach((item) => followUp.push(item.displayText));
            dispatch("openOptionsViewer", {
              dialogHeader: i18n.t("selectOptionMsg"),
              dialogData: followUp,
            });
            break;
          default:
            mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
            });
            dispatch("avatarTalk", i18n.t("kbError"));
            break;
        }
      }
    },
  },
};
